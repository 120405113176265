import React from "react"
import { graphql } from "gatsby"

import SEO from "../../components/Layout/SEO"
import Completed from "../../components/Completed/Completed"
import updated_completed from "../../../static/images/pages/updated_completed.gif"

const EpharmacyCompleted = () => {
  return (
    <>
      <SEO title="Submission Completed" />

      <Completed icon={updated_completed}>
        <p className="py-2 px-5 py-2-mobile px-0-mobile">
          A MedGrocer representative will contact you through email to confirm
          your order. If you don't receive an email within the next working day,
          please contact us at{" "}
          <a
            href={`mailto:${process.env.GATSBY_EMAIL_TO}`}
            className="has-text-weight-bold"
          >
            {process.env.GATSBY_EMAIL_TO}
          </a>
          .
        </p>
      </Completed>
    </>
  )
}
export default EpharmacyCompleted
